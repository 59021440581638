<script lang="ts" setup>
const { t } = useT();

const { open } = useAppModals();
const { durationLeft, isAvailable, levels, activeLevel, isCompleted, totalPrizes, isLastPrize } = useDailyLogin();

const handleDailyClick = () => {
	open("LazyOModalDailyLogin");
};
</script>

<template>
	<div :class="['wrapper', { completed: isCompleted }]" @click="handleDailyClick">
		<div class="content">
			<AText variant="toledo" :modifiers="['semibold']">
				<span>{{ t("Daily Reward") }}</span>
			</AText>
			<AText v-if="!isCompleted" variant="tulsa" :modifiers="['bold']" class="text-tlalnepantla label">
				<i18n-t keypath="Log in {key} days in a row to get MEGA prize">
					<template #key>{{ levels }}</template>
				</i18n-t>
			</AText>
			<div v-if="isCompleted" class="grand-prize">
				<AText as="div" class="subtitle" variant="topeka" :modifiers="['semibold']">
					<span>{{ t("Congrats, you've collected all rewards!") }}</span>
				</AText>

				<div class="coins">
					<MPrizeFund v-if="totalPrizes?.coins" variant="coins" img="/nuxt-img/prizes/funcoins@2x.png" :icon-size="22">
						<AText variant="tulsa" class="text-tlalnepantla text-count" :modifiers="['bold', 'uppercase']">
							{{ numberFormat(totalPrizes.coins) }}
						</AText>
					</MPrizeFund>
					<MPrizeFund
						v-if="totalPrizes?.entries"
						variant="entries"
						img="/nuxt-img/prizes/citycoins@2x.png"
						:icon-size="22"
					>
						<AText variant="tulsa" class="text-tlalnepantla text-count" :modifiers="['bold', 'uppercase']">
							{{ numberFormat(totalPrizes.entries) }}
						</AText>
					</MPrizeFund>
				</div>
				<MPrizeFund
					v-if="totalPrizes?.freeSpinCount"
					variant="entries"
					img="/nuxt-img/prizes/freespins.png"
					:icon-size="22"
				>
					<AText variant="tulsa" class="text-tlalnepantla text-count" :modifiers="['bold', 'uppercase']">
						<i18n-t keypath="{key} Free Plays">
							<template #key>{{ numberFormat(totalPrizes.freeSpinCount) }}</template>
						</i18n-t>
					</AText>
				</MPrizeFund>
			</div>
			<template v-else>
				<AButton v-if="isAvailable" variant="primary" class="btn" size="s">
					<AText variant="toledo" :modifiers="['semibold']" class="text-tlalnepantla">
						{{ t("Claim Reward") }}
					</AText>
				</AButton>
				<AText v-else variant="topeka" class="desc" :modifiers="['semibold']">
					<span>{{ t("Come back tomorrow to get the next prize!") }}</span>
				</AText>
			</template>
			<MDailyLoginLevels
				:levels="levels"
				:active="activeLevel"
				:time-end="durationLeft"
				:is-available="isAvailable"
				:is-completed="isCompleted"
				:is-last-prize="isLastPrize"
			/>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.wrapper {
	width: 295px;
	min-width: 280px;
	height: 228px;
	border-radius: 16px;
	background: var(--gosaba);
	display: flex;
	flex-direction: column;
	padding: 20px 16px 0;
	position: relative;
	overflow: hidden;
	cursor: pointer;

	&::before {
		content: "";
		background: var(--gondal);
		position: absolute;
		z-index: 1;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 50px;
	}

	&.completed {
		background: var(--gimcheon);
	}

	@include media-breakpoint-down(lg) {
		min-width: 295px;
	}

	@include media-breakpoint-down(md) {
		min-width: 100%;
	}
}

.content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 4px;
	position: relative;
	z-index: 1;
}

.label {
	line-height: 28px;
	text-shadow: 0 0 6px var(--carapicuiba);
	margin-bottom: 8px;
}

.btn {
	min-width: 140px;
	margin-bottom: 8px;
}

.desc {
	margin-bottom: 8px;
}

.subtitle {
	margin: 8px 0;
}

.grand-prize {
	.count,
	.coins {
		display: flex;
		align-items: center;
		gap: 4px;
	}

	.spin {
		margin-bottom: 12px;
	}

	.icon {
		width: 22px;
		height: 22px;
	}

	.label-time {
		margin-top: 10px;
	}
}
</style>
